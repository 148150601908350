import {
    createContext,
    useContext,
    useState,
    ReactNode,
    useCallback,
} from "react";
import {
    PurchaseSummaryData,
    PurchaseReportData,
} from "@snackpass/snackpass-types";
import { Moment } from "moment-timezone";
import moment from "moment-timezone";
import { usePurchaseReport } from "./use-query-mongo-orders";
import {
    Channel,
    Fulfillment,
    PaymentMethod,
    Provider,
} from "#sales-report/types";

export enum DefinedDates {
    TODAY = "Today",
    YESTERDAY = "Yesterday",
    LAST7DAYS = "Last 7 days",
    LAST30DAYS = "Last 30 days",
    LAST90DAYS = "Last 90 days",
    LAST365DAYS = "Last 365 days",
    SPECIFIC_DAY = "Specific Day",
    DATE_RANGE = "Date Range",
}

type OrdersContextType = {
    channels: Channel[];
    setChannels: (sources: Channel[]) => void;
    providers: Provider[];
    setProviders: (providers: Provider[]) => void;
    paymentMethods: PaymentMethod[];
    setPaymentMethods: (types: PaymentMethod[]) => void;
    startDate: Moment;
    endDate: Moment;
    duration: DefinedDates;
    setTimeRange: (range: { startDate: Moment; endDate: Moment }) => void;
    setDuration: (duration: DefinedDates) => void;
    fulfillments: Fulfillment[];
    setFulfillments: (types: Fulfillment[]) => void;
    purchaseReportRows: {
        data?: PurchaseReportData[];
        isLoading: boolean;
    };
    purchaseReportTotals: {
        days?: PurchaseSummaryData[];
        weeks?: PurchaseSummaryData[];
        months?: PurchaseSummaryData[];
        payouts?: PurchaseSummaryData[];
        total?: PurchaseSummaryData;
        isLoading: boolean;
    };
    refunded: boolean;
    setRefunded: (show: boolean) => void;
};

const OrdersContext = createContext<OrdersContextType | undefined>(undefined);

export function OrdersProvider({ children }: { children: ReactNode }) {
    const [channels, setChannels] = useState<Channel[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

    const [startDate, setStartDate] = useState<Moment>(moment().startOf("day"));
    const [endDate, setEndDate] = useState<Moment>(moment().endOf("day"));
    const [duration, setDuration] = useState<DefinedDates>(DefinedDates.TODAY);
    const [fulfillments, setFulfillments] = useState<Fulfillment[]>([]);
    const [refunded, setRefunded] = useState(false);

    const filters = {
        channels,
        providers,
        paymentMethods,
        fulfillments,
        ...(refunded && { refunded }),
    };

    const { data: rowsData, isLoading: rowsLoading } = usePurchaseReport(
        startDate,
        endDate,
        {
            ...filters,
            onlyRows: true,
            // https://linear.app/snackpass/issue/ENG-7525/mongo-purchasereportdata-table-of-orders-in-orders
            // need to finish this ticket to remove this
            includePurchase: true,
        },
    );

    const { data: totalsData, isLoading: totalsLoading } = usePurchaseReport(
        startDate,
        endDate,
        {
            ...filters,
            noRows: true,
        },
    );

    const setTimeRange = useCallback(
        ({ startDate, endDate }: { startDate: Moment; endDate: Moment }) => {
            if (startDate && endDate) {
                setStartDate(startDate);
                setEndDate(endDate);
            }
        },
        [],
    );

    return (
        <OrdersContext.Provider
            value={{
                channels,
                setChannels,
                providers,
                setProviders,
                paymentMethods,
                setPaymentMethods,
                startDate,
                endDate,
                duration,
                setTimeRange,
                setDuration,
                fulfillments,
                setFulfillments,
                purchaseReportRows: {
                    data: rowsData?.rows,
                    isLoading: rowsLoading,
                },
                purchaseReportTotals: {
                    days: totalsData?.days,
                    weeks: totalsData?.weeks,
                    months: totalsData?.months,
                    payouts: totalsData?.payouts,
                    total: totalsData?.total,
                    isLoading: totalsLoading,
                },
                refunded,
                setRefunded,
            }}
        >
            {children}
        </OrdersContext.Provider>
    );
}

export function useOrders() {
    const context = useContext(OrdersContext);
    if (context === undefined) {
        throw new Error("useOrders must be used within an OrdersProvider");
    }
    return context;
}
